// main.js

import { createApp } from "vue";
import App from "./App.vue";

import vue3GoogleLogin from "vue3-google-login";

// Create Vue application instance
const app = createApp(App);

app.use(vue3GoogleLogin, {
  clientId:
    "229284782094-36armp6bsrs5est6l68vv7vm5gl1gqbk.apps.googleusercontent.com",
});

// Mount the app to the #app element in the index.html
app.mount("#app");
