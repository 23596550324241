<template>
    <DeleteComp v-if="!IsProcessing && 0 == DeletionStatus" @onDelete="deleteUser" @onBack="onBack"/>
    <ErrorComp v-if="-1 == DeletionStatus && !IsProcessing" message="An error occurred while deleting your account." @onBack="DeletionStatus = 0"/>
    <ProcessingComp v-if="IsProcessing" :message="ProcessingMessage"/>
</template>

<script setup>
    import DeleteComp from "../components/DeleteComp.vue";
    import ErrorComp  from "../components/ErrorComp.vue";
    import ProcessingComp  from "../components/ProcessingComp.vue";

    import axios from "axios";
    import { ref, defineEmits } from 'vue';    
    const emit = defineEmits(['onCompleted', 'onAuthTimeout']);
    
    // -1 == Failed; 0 == Delete Button; 1 == Success
    const DeletionStatus = ref(0);
    const IsProcessing = ref(false);
    const ProcessingMessage = ref("Processing ...");

    const onBack = () => {
        DeletionStatus.value = 0;
        IsProcessing.value = false;
        emit('onAuthTimeout');
    }
    
    const deleteUser = async () => {
        IsProcessing.value = true;
        ProcessingMessage.value = "Processing Account Deletion ...";


        try {
            const originUri = window.location.origin;
            const response = await axios.delete(
            "https://dsligbuk54.execute-api.eu-central-1.amazonaws.com/default/accountdeletion-webapp",
            { 
                data: {
                    authCode: localStorage.getItem("auth_code"),
                    redirectUri: originUri,
                }
            }
            );
            handleResponse(response);

        } catch (error) {
            //console.error("Error contacting API:", error);
            
            setTimeout(() => {
                handleResponse(error.response);
            }, 2000);
        }
    };

    
    const handleResponse = (response) => {
        // If account was deleted or not found (-> deletion goal reached as well)
        if (200 == response.status || 404 == response.status) {
            DeletionStatus.value = 1;
            IsProcessing.value = false;
            emit('onCompleted');
        } 
        
        // If something went wrong regarding authentication -> return to login
        else if(401 == response.status || 403 == response.status) {
            DeletionStatus.value = 0;
            IsProcessing.value = false;
            emit('onAuthTimeout');
        }
        
        // otherwise handle error
        else {
            DeletionStatus.value = -1;
            IsProcessing.value = false;            
        }
    }
</script>

<style scoped>

</style>
  