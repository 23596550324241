<template>
    <p>You successfully authenticated as <b>{{ playername }}</b>.</p>
    <p>
        Before proceeding, please make sure to uninstall CYGRAM from all your
        devices, that are using the same account, to ensure the deletion can be
        executed correctly.
    </p>
    <div class="confirmation-container">
        <div class="checkbox-container" @click="checkboxChecked = !checkboxChecked">
        <img
            :src="
            checkboxChecked
                ? require('@/assets/checkbox-true.png')
                : require('@/assets/checkbox-false.png')
            "
            alt="Checkbox"
        />
        <span>
            I hereby confirm that I want to permanently delete my CYGRAM account
            and understand that this process cannot be undone.
        </span>
        </div>
    </div>

    <button @click="$emit('onDelete')" class="button-base button-error" v-if="checkboxChecked">
        Delete Account
    </button>
    
    <button @click="$emit('onBack')" class="button-base button-regular" v-if="!checkboxChecked">Back</button>
</template>

<script setup>
    import { ref, defineEmits, onMounted } from 'vue';   
    defineEmits(['onDelete', 'onBack']); 
    
    const checkboxChecked = ref(false);
    const playername = ref("a Pilot");

    onMounted(() => {
        if(localStorage.getItem("playerName"))
            playername.value = localStorage.getItem("playerName");
    });
</script>

<style scoped>  

    .confirmation-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: left;

        margin-top: 20px;
    }

    .checkbox-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 24px; /* Added margin to separate from the button */
    }

    .checkbox-container img {
        margin-right: 10px;
        width: 40px;
        height: 40px;
    }
</style>