<template>
  <div id="app">
    <div class="wrapper">
      <img src="./assets/cygram-logo.png" class="logo" />
      <div class="content">
        <AuthenticatedView v-if="IsAuthenticated && IsSupportedPlatform && !IsCompleted" @onAuthTimeout="onAuthTimeout" @onCompleted="onDeletionCompleted"/>
        <UnauthenticatedView v-if="!IsAuthenticated && IsSupportedPlatform && !IsCompleted" @onUserNotFound="onUserNotFound" @onAuthenticated="onAuthenticated"/>
        <UnsupportedPlatformView v-if="!IsSupportedPlatform && !IsCompleted"/>

        <div v-if="IsCompleted">
          {{ CompletedMessage }}
        </div>
      </div>
      <div class="footer">
        <a href="https://fbam.studio/imprint/" target="_blank">Imprint</a>
        <a href="https://cygram.cc/game/privacy-policy/english/" target="_blank">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import AuthenticatedView from "./views/AuthenticatedView.vue";
  import UnauthenticatedView from "./views/UnauthenticatedView.vue";
  import UnsupportedPlatformView from "./views/UnsupportedPlatformView.vue";

  import { onMounted } from "vue";
  import { ref } from 'vue';

  const IsAuthenticated = ref(false);
  const IsCompleted = ref(false);
  const CompletedMessage = ref("");
  const IsSupportedPlatform = ref(false);

  const onAuthenticated = () => {
    IsAuthenticated.value = true;
  }

  const onAuthTimeout = () => {
    localStorage.clear();
    IsAuthenticated.value = false;
  }

  const onDeletionCompleted = () => {
    localStorage.clear();
    IsAuthenticated.value = false;
    CompletedMessage.value = "Your CYGRAM account was successfully deleted.";
    IsCompleted.value = true;
  }

  const onUserNotFound = () => {
    localStorage.clear();
    IsAuthenticated.value = false;
    CompletedMessage.value = "There exists no CYGRAM account that is associated with your Google Account.";
    IsCompleted.value = true;
  }

  function IsIosDevice() {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  }

  function IsDesktopPlatform() {
    return (
      !/Android/i.test(navigator.userAgent) &&
      /Win|Mac|Linux/.test(navigator.platform)
    );
  }

  onMounted(() => {
    if(localStorage.getItem("expiresAt") && localStorage.getItem("auth_code") && localStorage.getItem("expiresAt") > Date.now())
        IsAuthenticated.value = true;
    else localStorage.clear();

    if(!IsIosDevice() && !IsDesktopPlatform()) IsSupportedPlatform.value = true;
  });
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.wrapper {
  font-family: Oxanium, sans-serif;
  background: #22242b;
  color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.logo {
  width: 300px;
  max-width: 75%;
  position: absolute;
  top: 10%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  width: 80%;
  max-width: 600px;
}

.content p {
  font-family: Oxanium;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  /* text-align: left; */
  margin-bottom: 16px;
  width: 100%;
}

.content p.centered {
  text-align: center;
}

.button-base {
  background-color: transparent;
  background-size: cover;
  background-position: center;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-family: Oxanium, sans-serif;

  cursor: pointer;

  margin-bottom: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74px;
  width: 220px;
  text-decoration: none;
  position: relative;

  font-family: Oxanium;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.5px;
}

.button-regular {
  background-image: url("~@/assets/button-regular.png");
  color: #eee;
}

.button-error {
  background-image: url("~@/assets/button-error.png");
  color: #FFC9C9;
}

.footer a {
  color: #aaa;
  text-decoration: none;
  font-size: 14px;
  line-height: 17.5px;
}

.footer {
  position: fixed;

  bottom: 20px;
  width: 100%;
}

.footer a:not(:last-child) {
  margin-right: 16px;
}

.footer a:hover {
  text-decoration: underline;
}
</style>



<style>

.button-b {
  background-image: url("~@/assets/button-error.png"); /* Adjust the path as necessary */
  background-color: transparent; /* Assuming you want the PNG to be the only background */
  background-size: cover;
  background-position: center;
  color: white; /* Adjust if needed to match your design */
  border: none;

  border-radius: 4px;
  font-family: Oxanium, sans-serif;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 24px;
  text-align: center; /* Ensure text is centered */
  display: flex; /* Use flex to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 60px; /* Or whatever your desired height is */
  width: 200px;
  text-decoration: none; /* If your button is an <a> element */
  position: relative; /* To position the text over the image */
}
</style>