<template>
    <LoginComp v-if="!AuthenticationFailed && !IsProcessing" @onLogin="login"/>
    <ErrorComp v-if="AuthenticationFailed && !IsProcessing" message="An error occurred during authentication." @onBack="AuthenticationFailed = false"/>
    <ProcessingComp v-if="IsProcessing" :message="ProcessingMessage"/>
</template>

<script setup>
    import LoginComp from "../components/LoginComp.vue";
    import ErrorComp  from "../components/ErrorComp.vue";
    import ProcessingComp  from "../components/ProcessingComp.vue";

    import axios from "axios";
    import { googleSdkLoaded } from "vue3-google-login";
    import { ref, defineEmits } from 'vue';    
    const emit = defineEmits(['onUserNotFound', 'onAuthenticated']);

    const IsProcessing = ref(false);
    const ProcessingMessage = ref("Processing ...");
    const AuthenticationFailed = ref(false);

    const login = () => {
        IsProcessing.value = true;
        ProcessingMessage.value = "Waiting for authentication ...";

        googleSdkLoaded((google) => {
            google.accounts.oauth2
            .initCodeClient({
                client_id:
                "229284782094-36armp6bsrs5est6l68vv7vm5gl1gqbk.apps.googleusercontent.com",
                scope: "https://www.googleapis.com/auth/games",
                ux_mode: "popup", // 'popup' or 'redirect'
                callback: callback,
                error_callback: callback
            })
            .requestCode();
        });
    };

    const callback = (response) => {
        if (response && response.code) {
            authCYGRAM(response.code);
        } else {    
            IsProcessing.value = false;
            AuthenticationFailed.value = true;
        }
    };

    const authCYGRAM = async (authCode) => {

        try {
            const originUri = window.location.origin;
            const response = await axios.post(
            "https://dsligbuk54.execute-api.eu-central-1.amazonaws.com/default/accountdeletion-webapp",
            {
                authCode: authCode,
                redirectUri: originUri,
            }
            );
            handleResponse(response, authCode);
        } catch (error) {
            //console.error("Error contacting API:", error);
            handleResponse(error.response, authCode);
        }
    };

    const handleResponse = (response, authCode) => {
        if (200 == response.status) {
            // Store authCode to expire in 5 minutes
            localStorage.setItem("expiresAt", Date.now() + 300000);
            localStorage.setItem("auth_code", authCode);

            if(response.data.playerName)
                localStorage.setItem("playerName", response.data.playerName);

            IsProcessing.value = false;
            emit('onAuthenticated');
        } else if (404 == response.status) {
            IsProcessing.value = false;
            emit('onUserNotFound');
        } else {            
            IsProcessing.value = false;
            AuthenticationFailed.value = true;
        }
    }
    
</script>

<style scoped>

</style>
  