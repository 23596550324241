<template>
    <p>
        Deleting an account through the web is currently not available for your
        platform.
    </p>
    <p>
        Please execute the account deletion through the Settings in CYGRAM.
    </p>
</template>

<script setup>

</script>

<style scoped>

</style>
  